import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from "react-bootstrap";

const TokenInstaFeed = () => {
    const [instaFeed, setInstaFeed] = useState([]);

    const [tokenId, setTokenId] = useState('');
    const [token, setToken] = useState('');
    const [igId, setIgId] = useState('');

    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getInstaFeed();
    }, []);

    const getInstaFeed = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/tokenInstaFeed');
            setInstaFeed(response.data);
            setTokenId(response.data[0]['id']);
            setToken(response.data[0]['token_instafeed']);
            setIgId(response.data[0]['ig_id']);
        } catch (error) {
            console.log(error);
        }
    }

    const saveTokenInstaFeed = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("token", token);
        formData.append("igId", igId);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/tokenInstaFeed', formData);

            getInstaFeed();

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const updateTokenInstaFeed = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("token", token);
        formData.append("igId", igId);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/tokenInstaFeed/${tokenId}`, formData);

            getInstaFeed();

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Card className="mt-5">
                <Card.Header as="h3">Token Instagram Feed</Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12}>
                                <Form onSubmit={instaFeed.length > 0 ? updateTokenInstaFeed : saveTokenInstaFeed}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Token</Form.Label>
                                        <Form.Control type="text" value={token} onChange={(e) => setToken(e.target.value)} placeholder="Token" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Intagram Id</Form.Label>
                                        <Form.Control type="text" value={igId} onChange={(e) => setIgId(e.target.value)} placeholder="Intagram Id" required />
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TokenInstaFeed;