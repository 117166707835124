import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Card, Col, Container, Figure, Form, InputGroup, Row, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from 'jodit-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Images from "./Images";
import CreatableSelect from 'react-select/creatable';

const NewsEdit = () => {
    const editor = useRef(null);

    const [category, setCategory] = useState([]);
    const [admin, setAdmin] = useState([]);
    const [newsTag, setNewsTag] = useState([]);

    const { id } = useParams();

    const [news_category_id, setNewsCategoryId] = useState('');
    const [subject, setSubject] = useState('');
    const [creator, setCreator] = useState('');
    const [publish, setPublish] = useState(false);
    const [date_publish, setDatePublish] = useState('');
    const [image_copyright, setImageCopyright] = useState('');
    const [description, setDescription] = useState('');
    const [imageId, setImageId] = useState('');
    const [filename, setFilename] = useState('');
    const [caption, setCaption] = useState('');
    const [preview, setPreview] = useState('');
    const [tagId, setTagId] = useState([]);

    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getNewsCategory();
        getAdmin();
        getNewsById(id);
        getNewsTag();
    }, [id]);

    const getNewsCategory = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/news_category_all');
            setCategory(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getAdmin = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/admin_all', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });
            setAdmin(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getNewsById = async (id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news/${id}`);
            setNewsCategoryId(response.data.news_category_id);
            setSubject(response.data.subject);
            setCreator(response.data.creator);
            setPublish(response.data.publish === 1 ? true : false);
            setDatePublish(response.data.date_publish);
            setImageCopyright(response.data.image_copyright);
            setDescription(response.data.description);
            setImageId(response.data.image_id);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.filename);
            setCaption(response.data.caption);
            setTagId(response.data.tag_id || []);
        } catch (error) {
            console.log(error);
        }
    }

    const getNewsTag = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/news_tag_all');
            const opt = [];
            let tags = response.data;
            tags.map((tag) => {
                return opt.push({
                    value: tag.id,
                    label: tag.name
                });
            });
            setNewsTag(opt);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId) => {
        setImageId(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename(response.data.name);
            setCaption(response.data.caption);
        } catch (error) {
            console.log(error);
        }
    }

    const updateNews = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("news_category_id", news_category_id);
        formData.append("subject", subject);
        formData.append("description", description);
        formData.append("creator", creator);
        formData.append("editor", localStorage.getItem('adminId'));
        formData.append("publish", publish);
        formData.append("image_copyright", image_copyright);
        formData.append("date_publish", date_publish);
        formData.append("tag_id", JSON.stringify(tagId));

        try {
            await axios.patch(process.env.REACT_APP_SITE + `/news/${id}`, formData);

            navigate('/news');
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const selectTag = (e) => {
        const opt = [];
        newsTag.map((tag) => {
            return opt.push({
                value: tag.value,
                label: tag.label
            });
        });

        for (let i = 0; i < e.length; i++) {
            let check = newsTag.some(tag => tag.value === e[i]['value']);
            if (check === false) {
                opt.push({
                    value: e[i]['value'],
                    label: e[i]['label']
                });
            }
        }

        setNewsTag(opt);
        setTagId(e.map(x => x.value));
    }

    return (
        <Container className="pb-5 pt-5 mt-2">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId); setShowModal(false); }} />

            <Card className="mt-5">
                <Card.Header as="h3">Edit News <Button href="/news" variant="info" className="float-end text-white" size="sm">Back</Button></Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <Form onSubmit={updateNews}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Category</Form.Label>
                                        <Col lg={4}>
                                            <Form.Select value={news_category_id} onChange={(e) => setNewsCategoryId(e.target.value)} required>
                                                <option hidden value={''}>Select Category</option>
                                                {category.map((category, index) => (
                                                    <option value={category.id} key={category.id}>{category.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Image</Form.Label>
                                        <Col lg={6}>
                                            <InputGroup className="mb-3">
                                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                                            </InputGroup>
                                            {preview ? (
                                                <Figure.Image width={256} height={256} alt="image" src={preview} />
                                            ) : ('')}
                                        </Col>
                                        <Form.Label style={{ fontSize: '12px' }}>{caption}</Form.Label>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Image Copyright</Form.Label>
                                        <Form.Control type="text" value={image_copyright} onChange={(e) => setImageCopyright(e.target.value)} placeholder="Image Copyright" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Creator</Form.Label>
                                        <Col lg={4}>
                                            <Form.Select value={creator} onChange={(e) => setCreator(e.target.value)} required>
                                                <option hidden value={''}>Select Creator</option>
                                                {admin.map((admin, index) => (
                                                    <option value={admin.id} key={admin.id}>{admin.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tag</Form.Label>
                                        <Col lg={6}>
                                            <CreatableSelect value={newsTag.filter(obj => tagId.includes(obj.value))} options={newsTag} onChange={selectTag} isMulti isClearable />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Publish</Form.Label>
                                        <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Col>
                                            <DatePicker className="form-control" timeInputLabel="Time:" dateFormat="yyyy-MM-dd hh:mm aa" showTimeInput selected={date_publish ? new Date(date_publish) : ''} onChange={(e) => setDatePublish(e)} placeholderText="yyyy-MM-dd hh:mm aa" fixedHeight disabled={publish === false} />
                                        </Col>
                                    </Form.Group>

                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                                            <Button href="/news" variant="secondary" className="float-end">Cancel</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default NewsEdit;