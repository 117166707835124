import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);
    const navigate = useNavigate();

    const Register = async (e) => {
        e.preventDefault();

        try {
            await axios.post(process.env.REACT_APP_SITE + '/admin', {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword
            });

            navigate('/login');
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);

                if (error.response.status === 400) {
                    setToast(true);
                }
            }
        }
    }

    return (
        <Container>
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto"></strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{msg}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Row className="justify-content-center mt-5">
                <Col sm={6}>
                    <Card border="secondary">
                        <Card.Header><h4 className="text-center">Register</h4></Card.Header>
                        <Card.Body>
                            <Form onSubmit={Register}>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Name</Form.Label>
                                    <Col>
                                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Email</Form.Label>
                                    <Col>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Password</Form.Label>
                                    <Col>
                                        <Form.Control type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Confirm Password</Form.Label>
                                    <Col>
                                        <Form.Control type="password" placeholder="********" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="submit" variant="success" className="float-end">Register</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;