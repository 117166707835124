import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from "react-bootstrap";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('refreshToken')) {
            navigate('/dashboard');
        }
    });

    const Auth = async (e) => {
        e.preventDefault();

        try {
            const resLogin = await axios.post(process.env.REACT_APP_SITE + '/login', {
                email: email,
                password: password
            });

            localStorage.setItem('token', resLogin.data.accessToken);
            localStorage.setItem('refreshToken', resLogin.data.refreshToken);
            const decoded = jwt_decode(resLogin.data.accessToken);
            localStorage.setItem('adminId', decoded.adminId);
            localStorage.setItem('adminName', decoded.name);
            localStorage.setItem('adminRole', decoded.role);
            localStorage.setItem('expire', decoded.exp);

            navigate('/dashboard');
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);

                if (error.response.status === 400) {
                    setToast(true);
                }
            }
        }
    }

    return (
        <Container>
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Row className="justify-content-center mt-5">
                <Col sm={6}>
                    <Card border="secondary">
                        <Card.Header><h4 className="text-center">Login</h4></Card.Header>
                        <Card.Body>
                            <Form onSubmit={Auth}>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Email</Form.Label>
                                    <Col>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm={3}>Password</Form.Label>
                                    <Col>
                                        <Form.Control type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type="submit" variant="success" className="float-end">Login</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;