import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Figure, Form, InputGroup, Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Images from "./Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTrash } from "@fortawesome/free-solid-svg-icons";

const About = () => {
    const editor = useRef(null);

    const [about, setAbout] = useState([]);

    const [aboutId, setAboutId] = useState('');
    const [name, setName] = useState('');
    const [imageId, setImageId] = useState('');
    const [description, setDescription] = useState('');
    const [preview, setPreview] = useState('');
    const [filename, setFilename] = useState('');

    const [currImage2Id, setCurrImage2Id] = useState('');
    const [image2Id, setImage2Id] = useState('');
    const [preview2, setPreview2] = useState('');
    const [filename2, setFilename2] = useState('');

    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getAbout();
    }, []);

    const getAbout = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/about');
            setAbout(response.data);
            setAboutId(response.data[0].id);
            setName(response.data[0].aboutName);
            setDescription(response.data[0].description);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data[0].path + response.data[0].filename);
            setPreview2(response.data[0].filename2 ? process.env.REACT_APP_SITE_IMAGE + response.data[0].path2 + response.data[0].filename2 : '');
            setCurrImage2Id(response.data[0].image2_id);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId) => {
        setImageId(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename(response.data.name);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage2 = async (imageId) => {
        setImage2Id(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview2(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename2(response.data.name);
        } catch (error) {
            console.log(error);
        }
    }

    const saveAbout = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("image2_id", image2Id);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/about', formData);

            getAbout();

            setMsg(resSave.data.msg);
            setToast(true);
            setFilename('');
            setFilename2('');
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
                setFilename('');
                setFilename2('');
            }
        }
    }

    const updateAbout = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("image2_id", image2Id);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/about/${aboutId}`, formData);

            getAbout();

            setMsg(resUpdate.data.msg);
            setToast(true);
            setFilename('');
            setFilename2('');
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
                setFilename('');
                setFilename2('');
            }
        }
    }

    const deleteImage2 = async () => {
        try {
            const formData = new FormData();
            formData.append("image_id", imageId);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("image2_id", 'delete');

            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/about/${aboutId}`, formData);

            getAbout();

            setShow(false);
            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId); setShowModal(false); }} />
            <Images show={showModal2} close={() => { setShowModal2(false) }} image={(imageId) => { loadImage2(imageId); setShowModal2(false); }} />

            <Card className="mt-5">
                <Card.Header as="h3">About</Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <Form onSubmit={about.length > 0 ? updateAbout : saveAbout}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Image</Form.Label>
                                        <Col lg={6}>
                                            <InputGroup className="mb-3">
                                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                                            </InputGroup>
                                            {preview ? (
                                                <Figure>
                                                    <Figure.Image width={256} height={256} alt="image" src={preview} />
                                                </Figure>
                                            ) : ('')}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Infographic</Form.Label>
                                        <Col lg={6}>
                                            <InputGroup className="mb-3">
                                                <Button variant="primary" onClick={() => { setShowModal2(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                                <Form.Control type="text" value={filename2} onChange={(e) => setFilename2(e.target.value)} placeholder="Filename..." disabled />
                                            </InputGroup>
                                            {preview2 ? (
                                                <Figure>
                                                    <Figure.Image width={256} height={256} alt="image" src={preview2} />
                                                    {currImage2Id ? (
                                                        <Button variant="danger" size="sm" className="position-absolute ms-1" onClick={() => setShow(true)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                    ) : ('')}
                                                </Figure>
                                            ) : ('')}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                                    </Form.Group>

                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Infographic</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this image?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
                    <Button variant="danger" onClick={() => deleteImage2()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default About;