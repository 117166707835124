import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Badge, Button, Col, Container, Dropdown, DropdownButton, Figure, Form, InputGroup, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Images from "./Images";
import { Link } from "react-router-dom";
import JoditEditor from 'jodit-react';
import CreatableSelect from 'react-select/creatable';
import ReactDatePicker from "react-datepicker";

const Gallery = () => {
    const editor = useRef(null);

    const [gallery, setGallery] = useState([]);
    const [galleryDetail, setGalleryDetail] = useState([]);
    const [newsTag, setNewsTag] = useState([]);

    const [galleryId, setGalleryId] = useState('');
    const [name, setName] = useState('');
    const [imageId, setImageId] = useState([]);
    const [filename, setFilename] = useState('');
    const [preview, setPreview] = useState([]);
    const [publish, setPublish] = useState(false);
    const [date_publish, setDatePublish] = useState('');
    const [description, setDescription] = useState('');
    const [tagId, setTagId] = useState([]);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(12);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getGallery(page, keyword, limit);
        getNewsTag();
    }, [page, keyword, limit]);

    const getGallery = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/gallery?search_query=${keyword}&page=${page}&limit=${limit}`);
            setGallery(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const getNewsTag = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/news_tag_all');
            const opt = [];
            let tags = response.data;
            tags.map((tag) => {
                return opt.push({
                    value: tag.id,
                    label: tag.name
                });
            });
            setNewsTag(opt);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId, choose) => {
        setImageId(imageId);

        try {
            let imgPreview = [];

            for (let i = 0; i < imageId.length; i++) {
                const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId[i]}`);
                imgPreview.push(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            }

            setPreview(imgPreview);

            if (choose) {
                setFilename(imageId.length > 1 ? imageId.length + ' files' : imageId.length + ' file');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const saveGallery = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("image_id", JSON.stringify(imageId));
        formData.append("publish", publish);
        formData.append("date_publish", date_publish);
        formData.append("description", description);
        formData.append("tag_id", JSON.stringify(tagId));

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/gallery', formData);

            handleClose();
            getGallery(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateGallery = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("image_id", JSON.stringify(imageId));
        formData.append("publish", publish);
        formData.append("date_publish", date_publish);
        formData.append("description", description);
        formData.append("tag_id", JSON.stringify(tagId));

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/gallery/${galleryId}`, formData);

            handleClose();
            getGallery(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateGalleryPublish = async (galleryId, publish) => {
        const formData = new FormData();
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/gallery_publish/${galleryId}`, formData);

            getGallery(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const deleteGallery = async (galleryId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/gallery/${galleryId}`);

            getGallery(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (galleryId) => {
        if (galleryId) {
            setShow(true);
            setGalleryId(galleryId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (galleryId) => {
        getNewsTag();

        if (galleryId) {
            setShowEdit(true);
            setShowAdd(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/gallery/${galleryId}`);
                setGalleryId(galleryId);
                setName(response.data.subject);
                loadImage(response.data.image_id);
                setPublish(response.data.publish === 1 ? true : false);
                setDatePublish(response.data.date_publish);
                setDescription(response.data.description);
                setTagId(response.data.tag_id || []);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
        }
    }

    const handleDetail = async (galleryId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/gallery/${galleryId}`);
            setGalleryDetail(response.data);
            loadImage(response.data.image_id);
            setNewsTag([]);

            let tagId = response.data.tag_id;

            if (tagId) {
                try {
                    const response = await axios.get(process.env.REACT_APP_SITE + `/news_tag_all?tags=${tagId}`);
                    setNewsTag(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setName('');
        setImageId([]);
        setFilename('');
        setPreview('');
        setPublish(false);
        setDatePublish('');
        setTagId([]);
        setNewsTag([]);
    }

    const selectTag = (e) => {
        const opt = [];
        newsTag.map((tag) => {
            return opt.push({
                value: tag.value,
                label: tag.label
            });
        });

        for (let i = 0; i < e.length; i++) {
            let check = newsTag.some(tag => tag.value === e[i]['value']);
            if (check === false) {
                opt.push({
                    value: e[i]['value'],
                    label: e[i]['label']
                });
            }
        }

        setNewsTag(opt);
        setTagId(e.map(x => x.value));
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId, 'choose'); setShowModal(false); }} />

            <h1 className="title mt-5">Gallery</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {gallery.length === 0 ?
                        <tr>
                            <td colSpan={5} className="text-center">Empty Data</td>
                        </tr>
                        :
                        gallery.map((gallery, index) => (
                            <tr key={gallery.id}>
                                <td>{(limit * (page - 1)) + index + 1}</td>
                                <td><Link className="link" onClick={() => handleDetail(gallery.id)}>{gallery.subject}</Link></td>
                                <td>{gallery.publish === 1 ? 'Publish' : 'Unpublish'}</td>
                                <td>{gallery.publish ? new Date(gallery.date_publish).toISOString().slice(0, 10) + ' ' + new Date(gallery.date_publish).toTimeString().slice(0, 5) : ''}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(gallery.id)}>Edit</Dropdown.Item>
                                        {gallery.publish === 1 ?
                                            <Dropdown.Item onClick={() => updateGalleryPublish(gallery.id, 0)}>Unpublish</Dropdown.Item> :
                                            <Dropdown.Item onClick={() => updateGalleryPublish(gallery.id, 1)}>Publish</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => handleClick(gallery.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this gallery?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteGallery(galleryId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Gallery</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveGallery} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Gallery Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Gallery name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            <Row>
                                {preview.length ? preview.map((preview, index) => (
                                    <Col sm={6} key={index}>
                                        <Figure>
                                            <Figure.Image alt="image" src={preview} />
                                        </Figure>
                                    </Col>
                                ))
                                    : ''
                                }
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag</Form.Label>
                            <CreatableSelect value={newsTag.filter(obj => tagId.includes(obj.value))} options={newsTag} onChange={selectTag} isMulti isClearable />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <ReactDatePicker className="form-control" timeInputLabel="Time:" dateFormat="yyyy-MM-dd hh:mm aa" showTimeInput selected={date_publish} onChange={(e) => setDatePublish(e)} placeholderText="yyyy-MM-dd hh:mm aa" fixedHeight disabled={publish === false} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Gallery</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateGallery} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Gallery Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Gallery name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            <Row>
                                {preview.length ? preview.map((preview, index) => (
                                    <Col sm={6} key={index}>
                                        <Figure>
                                            <Figure.Image alt="image" src={preview} />
                                        </Figure>
                                    </Col>
                                ))
                                    : ''
                                }
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag</Form.Label>
                            <CreatableSelect value={newsTag.filter(obj => tagId.includes(obj.value))} options={newsTag} onChange={selectTag} isMulti isClearable />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <ReactDatePicker className="form-control" timeInputLabel="Time:" dateFormat="yyyy-MM-dd hh:mm aa" showTimeInput selected={date_publish ? new Date(date_publish) : ''} onChange={(e) => setDatePublish(e)} placeholderText="yyyy-MM-dd hh:mm aa" fixedHeight disabled={publish === false} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Gallery</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Gallery Name :</Form.Label><br />
                            <Form.Label>{galleryDetail.subject}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Status :</Form.Label><br />
                            <Form.Label>{galleryDetail.publish === 1 ? 'Publish' : 'Unpublish'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date :</Form.Label><br />
                            <Form.Label>{galleryDetail.publish === 1 ? new Date(galleryDetail.date_publish).toISOString().slice(0, 10) + ' ' + new Date(galleryDetail.date_publish).toTimeString().slice(0, 5) : ''}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3 text-center">
                            <Row>
                                {preview.length ? preview.map((preview, index) => (
                                    <Col sm={6} key={index}>
                                        <Figure>
                                            <Figure.Image alt="image" src={preview} />
                                        </Figure>
                                    </Col>
                                ))
                                    : ''
                                }
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label>
                            <p dangerouslySetInnerHTML={{ __html: galleryDetail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Tags :</Form.Label><br />
                            <Form.Label>
                                {newsTag ?
                                    newsTag.map((tag, index) => (
                                        <Badge key={index} bg="primary" className="me-1">{tag.name}</Badge>
                                    ))
                                    : ''}
                            </Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Gallery;