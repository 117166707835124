import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Col, Container, Dropdown, DropdownButton, Figure, Form, InputGroup, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Images from "./Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
    const editor = useRef(null);

    const [banner, setBanner] = useState([]);
    const [bannerDetail, setBannerDetail] = useState([]);

    const [bannerId, setBannerId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');
    const [publish, setPublish] = useState(false);
    const [imageId, setImageId] = useState('');
    const [filename, setFilename] = useState('');
    const [preview, setPreview] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getBanner(page, keyword, limit);
    }, [page, keyword, limit]);

    const getBanner = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/banner?search_query=${keyword}&page=${page}&limit=${limit}`);
            setBanner(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId) => {
        setImageId(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename(response.data.name);
        } catch (error) {
            console.log(error);
        }
    }

    const saveBanner = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("link", link);
        formData.append("publish", publish);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/banner', formData);

            handleClose();
            getBanner(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateBanner = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("link", link);
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/banner/${bannerId}`, formData);

            handleClose();
            getBanner(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateBannerPublish = async (bannerId, publish) => {
        const formData = new FormData();
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/banner_publish/${bannerId}`, formData);

            getBanner(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const deleteBanner = async (bannerId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/banner/${bannerId}`);

            getBanner(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (bannerId) => {
        if (bannerId) {
            setShow(true);
            setBannerId(bannerId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (bannerId) => {
        if (bannerId) {
            setShowEdit(true);
            setShowAdd(false);
            setShowDetail(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/banner/${bannerId}`);
                setBannerId(bannerId);
                setName(response.data.name);
                setDescription(response.data.description);
                setLink(response.data.link);
                setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.filename);
                setPublish(response.data.publish === 1 ? true : false);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
            setShowDetail(false);
        }
    }

    const handleDetail = async (bannerId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/banner/${bannerId}`);
            setBannerDetail(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setName('');
        setDescription('');
        setLink('');
        setPublish(false);
        setFilename('');
        setPreview('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId); setShowModal(false); }} />

            <h1 className="title mt-5">Banner</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Link</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {banner.length === 0 ?
                        <tr>
                            <td colSpan={6} className="text-center">Empty Data</td>
                        </tr>
                        :
                        banner.map((banner, index) => (
                            <tr key={banner.id}>
                                <td>{(limit * (page - 1)) + index + 1}</td>
                                <td><Link className="link" onClick={() => handleDetail(banner.id)}>{banner.name}</Link></td>
                                <td>{banner.link}</td>
                                <td>{banner.publish === 1 ? 'Publish' : 'Unpublish'}</td>
                                <td>{banner.publish ? new Date(banner.date_publish).toISOString().slice(0, 10) + ' ' + new Date(banner.date_publish).toTimeString().slice(0, 5) : ''}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(banner.id)}>Edit</Dropdown.Item>
                                        {banner.publish === 1 ?
                                            <Dropdown.Item onClick={() => updateBannerPublish(banner.id, 0)}>Unpublish</Dropdown.Item> :
                                            <Dropdown.Item onClick={() => updateBannerPublish(banner.id, 1)}>Publish</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => handleClick(banner.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this banner?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteBanner(bannerId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Banner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveBanner} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Banner Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Banner name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Banner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateBanner} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Banner Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Banner name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Banner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3 text-center">
                            <Figure>
                                <Figure.Image style={{ width: "100%" }} alt="image" src={process.env.REACT_APP_SITE_IMAGE + bannerDetail.path + bannerDetail.filename} />
                            </Figure>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Banner Name :</Form.Label><br />
                            <Form.Label>{bannerDetail.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label>
                            <p dangerouslySetInnerHTML={{ __html: bannerDetail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Link :</Form.Label><br />
                            <Form.Label>{bannerDetail.link ? bannerDetail.link : '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Status :</Form.Label><br />
                            <Form.Label>{bannerDetail.publish === 1 ? 'Publish' : 'Unpublish'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date :</Form.Label><br />
                            <Form.Label>{bannerDetail.publish === 1 ? new Date(bannerDetail.date_publish).toISOString().slice(0, 10) + ' ' + new Date(bannerDetail.date_publish).toTimeString().slice(0, 5) : ''}</Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container >
    );
}

export default Banner;