import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Col, Container, Dropdown, DropdownButton, Figure, Form, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const Partner = () => {
    const editor = useRef(null);

    const [partner, setPartner] = useState([]);
    const [partnerDetail, setPartnerDetail] = useState([]);

    const [partnerId, setPartnerId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const [preview, setPreview] = useState('');
    const [line, setLine] = useState(1);
    const [position, setPosition] = useState(1);
    const [link, setLink] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getPartner(page, keyword, limit);
    }, [page, keyword, limit]);

    const getPartner = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/partner?search_query=${keyword}&page=${page}&limit=${limit}`);
            setPartner(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = (e) => {
        const image = e.target.files[0];
        setFile(image);
        setPreview(URL.createObjectURL(image));
    }

    const savePartner = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("line", line);
        formData.append("position", position);
        formData.append("link", link);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/partner', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            });

            handleClose();
            getPartner(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updatePartner = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("line", line);
        formData.append("position", position);
        formData.append("link", link);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/partner/${partnerId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            handleClose();
            getPartner(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const deletePartner = async (partnerId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/partner/${partnerId}`);

            getPartner(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (partnerId) => {
        if (partnerId) {
            setShow(true);
            setPartnerId(partnerId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (partnerId) => {
        if (partnerId) {
            setShowEdit(true);
            setShowAdd(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/partner/${partnerId}`);
                setPartnerId(partnerId);
                setName(response.data.name);
                setDescription(response.data.description);
                setFile(response.data.image);
                setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.url);
                setLine(response.data.line);
                setPosition(response.data.position);
                setLink(response.data.link || '');
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
        }
    }

    const handleDetail = async (partnerId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/partner/${partnerId}`);
            setPartnerDetail(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setName('');
        setDescription('');
        setFile('');
        setPreview('');
        setLine('');
        setPosition('');
        setLink('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">Partners</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Line</th>
                        <th>Position</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {partner.length === 0 ?
                        <tr>
                            <td colSpan={5} className="text-center">Empty Data</td>
                        </tr>
                        :
                        partner.map((partner, index) => (
                            <tr key={partner.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td><Link className="link" onClick={() => handleDetail(partner.id)}>{partner.name}</Link></td>
                                <th>{partner.line}</th>
                                <th>{partner.position}</th>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(partner.id)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleClick(partner.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Partner</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this partner?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deletePartner(partnerId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Partner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={savePartner} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Partner name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" onChange={loadImage} className="mb-3" required />
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Line</Form.Label>
                            <Form.Control type="number" value={line} onChange={(e) => setLine(e.target.value)} min={1} max={3} placeholder="Partner Line" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Position</Form.Label>
                            <Form.Control type="number" value={position} onChange={(e) => setPosition(e.target.value)} min={1} placeholder="Partner Position" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Link</Form.Label>
                            <Form.Control type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Partner link" />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Partner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updatePartner} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Partner name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" onChange={loadImage} className="mb-3" />
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Line</Form.Label>
                            <Form.Control type="number" value={line} onChange={(e) => setLine(e.target.value)} min={1} max={3} placeholder="Partner Line" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Position</Form.Label>
                            <Form.Control type="number" value={position} onChange={(e) => setPosition(e.target.value)} min={1} placeholder="Partner Position" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Partner Link</Form.Label>
                            <Form.Control type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Partner link" />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Partner</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3">
                            <Figure>
                                <Figure.Image style={{ width: "100%" }} alt="image" src={process.env.REACT_APP_SITE_IMAGE + partnerDetail.url} />
                            </Figure>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Partner Name :</Form.Label><br />
                            <Form.Label>{partnerDetail.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label>
                            <p dangerouslySetInnerHTML={{ __html: partnerDetail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Partner Line :</Form.Label><br />
                            <Form.Label>{partnerDetail.line}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Partner Position :</Form.Label><br />
                            <Form.Label>{partnerDetail.position}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Partner Link :</Form.Label><br />
                            <Form.Label>{partnerDetail.link}</Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Partner;