import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Button, Col, Container, Dropdown, DropdownButton, Figure, Form, InputGroup, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from 'jodit-react';
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Images from "./Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Team = () => {
    const editor = useRef(null);

    const [team, setTeam] = useState([]);
    const [team_detail, setTeamDetail] = useState([]);

    const [teamId, setTeamId] = useState('');
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [place_of_birth, setPlaceOfBirth] = useState('');
    const [date_of_birth, setDateOfBirth] = useState('');
    const [nationality, setNationality] = useState('');
    const [description, setDescription] = useState('');
    const [join_date, setJoinDate] = useState('');
    const [origin, setOrigin] = useState('');
    const [publish, setPublish] = useState(false);
    const [imageId, setImageId] = useState('');
    const [filename, setFilename] = useState('');
    const [preview, setPreview] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getTeam(page, keyword, limit);
    }, [page, keyword, limit]);

    const getTeam = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/team?search_query=${keyword}&page=${page}&limit=${limit}`);
            setTeam(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId) => {
        setImageId(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename(response.data.name);
        } catch (error) {
            console.log(error);
        }
    }

    const saveTeam = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("place_of_birth", place_of_birth);
        formData.append("date_of_birth", date_of_birth);
        formData.append("nationality", nationality);
        formData.append("description", description);
        formData.append("join_date", join_date);
        formData.append("origin", origin);
        formData.append("publish", publish);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/team', formData);

            handleClose();
            getTeam(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateTeam = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("place_of_birth", place_of_birth);
        formData.append("date_of_birth", date_of_birth);
        formData.append("nationality", nationality);
        formData.append("description", description);
        formData.append("join_date", join_date);
        formData.append("origin", origin);
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/team/${teamId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            handleClose();
            getTeam(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateTeamPublish = async (teamId, publish) => {
        const formData = new FormData();
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/team_publish/${teamId}`, formData);

            getTeam(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const deleteTeam = async (teamId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/team/${teamId}`);

            getTeam(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (teamId) => {
        if (teamId) {
            setShow(true);
            setTeamId(teamId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (teamId) => {
        if (teamId) {
            setShowEdit(true);
            setShowAdd(false);
            setShowDetail(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/team/${teamId}`);
                setTeamId(teamId);
                setName(response.data.name);
                setPosition(response.data.position);
                setPlaceOfBirth(response.data.place_of_birth || '');
                setDateOfBirth(response.data.date_of_birth || '');
                setNationality(response.data.nationality || '');
                setDescription(response.data.description || '');
                setJoinDate(response.data.join_date || '');
                setOrigin(response.data.origin || '');
                setPublish(response.data.publish === 1 ? true : false);
                setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.filename);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
            setShowDetail(false);
        }
    }

    const handleDetail = async (teamId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/team/${teamId}`);
            setTeamDetail(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setName('');
        setPosition('');
        setPlaceOfBirth('');
        setDateOfBirth('');
        setNationality('');
        setDescription('');
        setJoinDate('');
        setOrigin('');
        setPublish('');
        setFilename('');
        setPreview('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId); setShowModal(false); }} />

            <h1 className="title mt-5">Team</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Nationality</th>
                        <th>Status</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {team.length === 0 ?
                        <tr>
                            <td colSpan={6} className="text-center">Empty Data</td>
                        </tr>
                        :
                        team.map((team, index) => (
                            <tr key={team.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td><Link className="link" onClick={() => handleDetail(team.id)}>{team.name}</Link></td>
                                <td>{team.position}</td>
                                <td>{team.nationality}</td>
                                <td>{team.publish === 1 ? 'Publish' : 'Unpublish'}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(team.id)}>Edit</Dropdown.Item>
                                        {team.publish === 1 ?
                                            <Dropdown.Item onClick={() => updateTeamPublish(team.id, 0)}>Unpublish</Dropdown.Item> :
                                            <Dropdown.Item onClick={() => updateTeamPublish(team.id, 1)}>Publish</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => handleClick(team.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this team?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteTeam(teamId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Team</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveTeam} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Team name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Position</Form.Label>
                            <Form.Select value={position} onChange={(e) => setPosition(e.target.value)} required>
                                <option hidden value={''}>Select Position</option>
                                <option value="MANAGER">MANAGER</option>
                                <option value="HEAD COACH">HEAD COACH</option>
                                <option value="ASSISTANT COACH">ASSISTANT COACH</option>
                                <option value="PHYSIOTHERAPY">PHYSIOTHERAPY</option>
                                <option value="UTILITY">UTILITY</option>
                                <option value="OFFICIAL">OFFICIAL</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Place Of Birth</Form.Label>
                            <Form.Control type="text" value={place_of_birth} onChange={(e) => setPlaceOfBirth(e.target.value)} placeholder="Place Of Birth" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date_of_birth} onChange={(e) => setDateOfBirth(e)} placeholderText="yyyy-mm-dd" fixedHeight />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control type="text" value={nationality} onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Origin</Form.Label>
                            <Form.Control type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} placeholder="Origin" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Team</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateTeam} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Team name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Position</Form.Label>
                            <Form.Select value={position} onChange={(e) => setPosition(e.target.value)} required>
                                <option hidden value={''}>Select Position</option>
                                <option value="MANAGER">MANAGER</option>
                                <option value="HEAD COACH">HEAD COACH</option>
                                <option value="ASSISTANT COACH">ASSISTANT COACH</option>
                                <option value="PHYSIOTHERAPY">PHYSIOTHERAPY</option>
                                <option value="UTILITY">UTILITY</option>
                                <option value="OFFICIAL">OFFICIAL</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Place Of Birth</Form.Label>
                            <Form.Control type="text" value={place_of_birth} onChange={(e) => setPlaceOfBirth(e.target.value)} placeholder="Place Of Birth" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date_of_birth ? new Date(date_of_birth) : null} onChange={(e) => setDateOfBirth(e)} placeholderText="yyyy-mm-dd" fixedHeight />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control type="text" value={nationality} onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Origin</Form.Label>
                            <Form.Control type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} placeholder="Origin" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Team</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3 text-center">
                            <Figure>
                                <Figure.Image style={{ width: "100%" }} alt="image" src={process.env.REACT_APP_SITE_IMAGE + team_detail.path + team_detail.filename} />
                            </Figure>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Team Name :</Form.Label><br />
                            <Form.Label>{team_detail.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Team Name :</Form.Label><br />
                            <Form.Label>{team_detail.position}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Place Of Birth :</Form.Label><br />
                            <Form.Label>{team_detail.place_of_birth}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date Of Birth :</Form.Label><br />
                            <Form.Label>{team_detail.date_of_birth}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Place Of Birth :</Form.Label><br />
                            <Form.Label>{team_detail.nationality}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label><br />
                            <p dangerouslySetInnerHTML={{ __html: team_detail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Origin :</Form.Label><br />
                            <Form.Label>{team_detail.origin}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Status :</Form.Label><br />
                            <Form.Label>{team_detail.publish === 1 ? 'Publish' : 'Unpublish'}</Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Team;