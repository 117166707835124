import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Container, Dropdown, DropdownButton, Form, Modal, Offcanvas, Table, Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const Standing = () => {
    const [standing, setStanding] = useState([]);
    const [season, setSeason] = useState([]);

    const [season_Id, setSeason_Id] = useState('');

    const [url, setUrl] = useState('');
    const [seasonId, setSeasonId] = useState('');
    const [standingId, setStandingId] = useState('');
    const [rank, setRank] = useState('');
    const [clubId, setClubId] = useState('');
    const [team, setTeam] = useState('');
    const [game, setGame] = useState('');
    const [win, setWin] = useState('');
    const [lose, setLose] = useState('');
    const [point, setPoint] = useState('');

    const [show, setShow] = useState(false);
    const [showSync, setshowSync] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getStanding(season_Id);
        getSeason();
    }, [season_Id]);

    const getStanding = async (season_Id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/standing?year=${season_Id}`);
            setStanding(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getSeason = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/season_all');
            setSeason(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const syncStanding = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("url", url);
        formData.append("season_id", seasonId);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/standing_crawling', formData);

            handleClose();
            getStanding(season_Id);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateStanding = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("rank", rank);
        formData.append("club_id", clubId);
        formData.append("game", game);
        formData.append("win", win);
        formData.append("lose", lose);
        formData.append("point", point);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/standing/${standingId}`, formData);

            handleClose();
            getStanding(season_Id);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const deleteStanding = async (standingId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/standing/${standingId}`);

            getStanding(season_Id);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (standingId) => {
        if (standingId) {
            setShow(true);
            setStandingId(standingId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (standingId) => {
        if (standingId) {
            setShowEdit(true);
            setshowSync(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/standing/${standingId}`);
                setStandingId(standingId);
                setRank(response.data.rank);
                setClubId(response.data.club_id)
                setTeam(response.data.team);
                setGame(response.data.game);
                setWin(response.data.win);
                setLose(response.data.lose);
                setPoint(response.data.point);
            } catch (error) {
                console.log(error);
            }
        } else {
            setshowSync(true);
            setShowEdit(false);
        }
    }

    const handleClose = () => {
        setshowSync(false);
        setShowEdit(false);
        setUrl('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">Standing</h1>
            <Button onClick={() => handleForm()} variant="success">Sync Standing</Button>
            <Col lg={2} className="mt-3">
                <Form.Select value={season_Id} onChange={(e) => setSeason_Id(e.target.value)}>
                    <option hidden value={''}>Season</option>
                    {season.map((season, index) => (
                        <option value={season.id} key={index}>{season.name}</option>
                    ))}
                </Form.Select>
            </Col>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>RANK</th>
                        <th>TEAM</th>
                        <th>G</th>
                        <th>W</th>
                        <th>L</th>
                        <th>PTS</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {standing.length === 0 ?
                        <tr>
                            <td colSpan={7} className="text-center">Empty Data</td>
                        </tr>
                        :
                        standing.map((standing, index) => (
                            <tr key={standing.id}>
                                <td>{standing.rank}</td>
                                <td>{standing.team}</td>
                                <td>{standing.game}</td>
                                <td>{standing.win}</td>
                                <td>{standing.lose}</td>
                                <td>{standing.point}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(standing.id)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleClick(standing.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Offcanvas show={showSync} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sync Standing</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={syncStanding}>
                        <Col className="mb-3">
                            <Form.Select value={seasonId} onChange={(e) => setSeasonId(e.target.value)}>
                                <option hidden value={''}>Season</option>
                                {season.map((season, index) => (
                                    <option value={season.id} key={index}>{season.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Form.Group className="mb-3">
                            <Form.Label>URL IBL</Form.Label>
                            <Form.Control type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL IBL" />
                        </Form.Group>
                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Standing</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateStanding}>
                        <Form.Group className="mb-3">
                            <Form.Label>Rank</Form.Label>
                            <Form.Control type="number" value={rank} onChange={(e) => setRank(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team</Form.Label>
                            <Form.Control type="number" value={clubId} onChange={(e) => setClubId(e.target.value)} hidden />
                            <Form.Control type="text" value={team} onChange={(e) => setTeam(e.target.value)} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Game</Form.Label>
                            <Form.Control type="number" value={game} onChange={(e) => setGame(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Win</Form.Label>
                            <Form.Control type="number" value={win} onChange={(e) => setWin(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Lose</Form.Label>
                            <Form.Control type="number" value={lose} onChange={(e) => setLose(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Point</Form.Label>
                            <Form.Control type="number" value={point} onChange={(e) => setPoint(e.target.value)} />
                        </Form.Group>
                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Standing</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this news standing?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteStanding(standingId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Standing;