import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Container, Dropdown, DropdownButton, Figure, Form, InputGroup, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Images from "./Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Player = () => {
    const editor = useRef(null);

    const [player, setPlayer] = useState([]);
    const [player_detail, setPlayerDetail] = useState([]);

    const [playerId, setPlayerId] = useState('');
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [shirt_number, setShirtNumber] = useState('');
    const [player_name, setPlayerName] = useState('');
    const [place_of_birth, setPlaceOfBirth] = useState('');
    const [date_of_birth, setDateOfBirth] = useState('');
    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [nationality, setNationality] = useState('');
    const [description, setDescription] = useState('');
    const [join_date, setJoinDate] = useState('');
    const [origin, setOrigin] = useState('');
    const [publish, setPublish] = useState(false);
    const [imageId, setImageId] = useState('');
    const [filename, setFilename] = useState('');
    const [preview, setPreview] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getPlayer(page, keyword, limit);
    }, [page, keyword, limit]);

    const getPlayer = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/player?search_query=${keyword}&page=${page}&limit=${limit}`);
            setPlayer(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = async (imageId) => {
        setImageId(imageId);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images/${imageId}`);
            setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.name);
            setFilename(response.data.name);
        } catch (error) {
            console.log(error);
        }
    }

    const savePlayer = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("shirt_number", shirt_number);
        formData.append("player_name", player_name);
        formData.append("place_of_birth", place_of_birth);
        formData.append("date_of_birth", date_of_birth);
        formData.append("weight", weight);
        formData.append("height", height);
        formData.append("nationality", nationality);
        formData.append("description", description);
        formData.append("join_date", join_date);
        formData.append("origin", origin);
        formData.append("publish", publish);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/player', formData);

            handleClose();
            getPlayer(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updatePlayer = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_id", imageId);
        formData.append("name", name);
        formData.append("position", position);
        formData.append("shirt_number", shirt_number);
        formData.append("player_name", player_name);
        formData.append("place_of_birth", place_of_birth);
        formData.append("date_of_birth", date_of_birth);
        formData.append("weight", weight);
        formData.append("height", height);
        formData.append("nationality", nationality);
        formData.append("description", description);
        formData.append("join_date", join_date);
        formData.append("origin", origin);
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/player/${playerId}`, formData);

            handleClose();
            getPlayer(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updatePlayerPublish = async (playerId, publish) => {
        const formData = new FormData();
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/player_publish/${playerId}`, formData);

            getPlayer(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const deletePlayer = async (playerId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/player/${playerId}`);

            getPlayer(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (playerId) => {
        if (playerId) {
            setShow(true);
            setPlayerId(playerId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (playerId) => {
        if (playerId) {
            setShowEdit(true);
            setShowAdd(false);
            setShowDetail(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/player/${playerId}`);
                setPlayerId(playerId);
                setName(response.data.name);
                setPosition(response.data.position);
                setShirtNumber(response.data.shirt_number);
                setPlayerName(response.data.player_name);
                setPlaceOfBirth(response.data.place_of_birth || '');
                setDateOfBirth(response.data.date_of_birth);
                setWeight(response.data.weight);
                setHeight(response.data.height);
                setNationality(response.data.nationality || '');
                setDescription(response.data.description || '');
                setJoinDate(response.data.join_date || '');
                setOrigin(response.data.origin || '');
                setPublish(response.data.publish === 1 ? true : false);
                setPreview(process.env.REACT_APP_SITE_IMAGE + response.data.path + response.data.filename);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
            setShowDetail(false);
        }
    }

    const handleDetail = async (playerId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/player/${playerId}`);
            setPlayerDetail(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setPlayerId('');
        setName('');
        setPosition('');
        setShirtNumber('');
        setPlayerName('');
        setPlaceOfBirth('');
        setDateOfBirth('');
        setWeight('');
        setHeight('');
        setNationality('');
        setDescription('');
        setJoinDate('');
        setOrigin('');
        setPublish(false);
        setFilename('');
        setPreview('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Images show={showModal} close={() => { setShowModal(false) }} image={(imageId) => { loadImage(imageId); setShowModal(false); }} />

            <h1 className="title mt-5">Player</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Number</th>
                        <th>Player Name</th>
                        <th>Nationality</th>
                        <th>Status</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {player.length === 0 ?
                        <tr>
                            <td colSpan={8} className="text-center">Empty Data</td>
                        </tr>
                        :
                        player.map((player, index) => (
                            <tr key={player.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td><Link className="link" onClick={() => handleDetail(player.id)}>{player.name}</Link></td>
                                <td>{player.position}</td>
                                <td>{player.shirt_number}</td>
                                <td>{player.player_name}</td>
                                <td>{player.nationality}</td>
                                <td>{player.publish === 1 ? 'Publish' : 'Unpublish'}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(player.id)}>Edit</Dropdown.Item>
                                        {player.publish === 1 ?
                                            <Dropdown.Item onClick={() => updatePlayerPublish(player.id, 0)}>Unpublish</Dropdown.Item> :
                                            <Dropdown.Item onClick={() => updatePlayerPublish(player.id, 1)}>Publish</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => handleClick(player.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Player</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this player?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deletePlayer(playerId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Player</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={savePlayer} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Player Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Player name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Shirt Name</Form.Label>
                            <Form.Control type="text" value={player_name} onChange={(e) => setPlayerName(e.target.value)} placeholder="Shirt Name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Shirt Number</Form.Label>
                            <Form.Control type="number" value={shirt_number} onChange={(e) => setShirtNumber(e.target.value)} placeholder="Shirt Number" min={0} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Position</Form.Label>
                            <Form.Select value={position} onChange={(e) => setPosition(e.target.value)} required>
                                <option hidden value={''}>Select Position</option>
                                <option value="PG">PG</option>
                                <option value="SG">SG</option>
                                <option value="SF">SF</option>
                                <option value="PF">PF</option>
                                <option value="C">C</option>
                                <option value="C/PW">C/PW</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Place Of Birth</Form.Label>
                            <Form.Control type="text" value={place_of_birth} onChange={(e) => setPlaceOfBirth(e.target.value)} placeholder="Place Of Birth" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date_of_birth} onChange={(e) => setDateOfBirth(e)} placeholderText="yyyy-mm-dd" fixedHeight required />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Height</Form.Label>
                            <Form.Control type="number" value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Height" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Weight</Form.Label>
                            <Form.Control type="number" value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control type="text" value={nationality} onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Join Date</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={join_date} onChange={(e) => setJoinDate(e)} placeholderText="yyyy-mm-dd" fixedHeight />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Origin</Form.Label>
                            <Form.Control type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} placeholder="Origin" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Team</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updatePlayer} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Player Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Player name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Shirt Name</Form.Label>
                            <Form.Control type="text" value={player_name} onChange={(e) => setPlayerName(e.target.value)} placeholder="Shirt Name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <InputGroup className="mb-3">
                                <Button variant="primary" onClick={() => { setShowModal(true) }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Browse File</Button>
                                <Form.Control type="text" value={filename} onChange={(e) => setFilename(e.target.value)} placeholder="Filename..." disabled />
                            </InputGroup>
                            {preview ? (
                                <Figure>
                                    <Figure.Image style={{ width: "100%" }} alt="image" src={preview} />
                                </Figure>
                            ) : ('')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Shirt Number</Form.Label>
                            <Form.Control type="number" value={shirt_number} onChange={(e) => setShirtNumber(e.target.value)} placeholder="Shirt Number" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Position</Form.Label>
                            <Form.Select value={position} onChange={(e) => setPosition(e.target.value)} required>
                                <option hidden value={''}>Select Position</option>
                                <option value="PG">PG</option>
                                <option value="SG">SG</option>
                                <option value="SF">SF</option>
                                <option value="PF">PF</option>
                                <option value="C">C</option>
                                <option value="C/PW">C/PW</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Place Of Birth</Form.Label>
                            <Form.Control type="text" value={place_of_birth} onChange={(e) => setPlaceOfBirth(e.target.value)} placeholder="Place Of Birth" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date_of_birth ? new Date(date_of_birth) : null} onChange={(e) => setDateOfBirth(e)} placeholderText="yyyy-mm-dd" fixedHeight required />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Height</Form.Label>
                            <Form.Control type="number" value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Height" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Weight</Form.Label>
                            <Form.Control type="number" value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="Weight" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control type="text" value={nationality} onChange={(e) => setNationality(e.target.value)} placeholder="Nationality" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <JoditEditor ref={editor} value={description} tabIndex={1} onBlur={newContent => setDescription(newContent)} onChange={newContent => { }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Join Date</Form.Label>
                            <Col>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={join_date ? new Date(join_date) : null} onChange={(e) => setJoinDate(e)} placeholderText="yyyy-mm-dd" fixedHeight />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Origin</Form.Label>
                            <Form.Control type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} placeholder="Origin" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish</Form.Label>
                            <Form.Check type="switch" label={publish ? "On" : "Off"} checked={publish} onChange={() => setPublish(!publish)} />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Player</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3 text-center">
                            <Figure>
                                <Figure.Image style={{ width: "100%" }} alt="image" src={process.env.REACT_APP_SITE_IMAGE + player_detail.path + player_detail.filename} />
                            </Figure>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Player Name :</Form.Label><br />
                            <Form.Label>{player_detail.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Position :</Form.Label><br />
                            <Form.Label>{player_detail.position}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Shirt Number :</Form.Label><br />
                            <Form.Label>{player_detail.shirt_number}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Shirt Name :</Form.Label><br />
                            <Form.Label>{player_detail.player_name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Place Of Birth :</Form.Label><br />
                            <Form.Label>{player_detail.place_of_birth}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date Of Birth :</Form.Label><br />
                            <Form.Label>{player_detail.date_of_birth}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Weight :</Form.Label><br />
                            <Form.Label>{player_detail.weight}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Height :</Form.Label><br />
                            <Form.Label>{player_detail.height}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Nationality :</Form.Label><br />
                            <Form.Label>{player_detail.nationality}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label><br />
                            <p dangerouslySetInnerHTML={{ __html: player_detail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Join Date :</Form.Label><br />
                            <Form.Label>{player_detail.join_date}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Origin :</Form.Label><br />
                            <Form.Label>{player_detail.origin}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Status :</Form.Label><br />
                            <Form.Label>{player_detail.publish === 1 ? 'Publish' : 'Unpublish'}</Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Player;