import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from "react-bootstrap";

const TicketPass = () => {
    const [ticketPass, setTicketPass] = useState([]);

    const [ticketPassId, setTicketPassId] = useState('');
    const [seasonPass, setSeasonPass] = useState('');
    const [daysPass, setDayspass] = useState('');

    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getTicketPass();
    }, []);

    const getTicketPass = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/ticket_pass');
            setTicketPass(response.data);
            setTicketPassId(response.data[0]['id']);
            setSeasonPass(response.data[0]['season_pass'] || '');
            setDayspass(response.data[0]['days_pass'] || '');
        } catch (error) {
            console.log(error);
        }
    }

    const saveTicketPass = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("season_pass", seasonPass);
        formData.append("days_pass", daysPass);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/ticket_pass', formData);

            getTicketPass();

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const updateTicketPass = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("season_pass", seasonPass);
        formData.append("days_pass", daysPass);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/ticket_pass/${ticketPassId}`, formData);

            getTicketPass();

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <Card className="mt-5">
                <Card.Header as="h3">Ticket Pass</Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12}>
                                <Form onSubmit={ticketPass.length > 0 ? updateTicketPass : saveTicketPass}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Season Pass</Form.Label>
                                        <Form.Control type="text" value={seasonPass} onChange={(e) => setSeasonPass(e.target.value)} placeholder="Season Pass" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Days Pass</Form.Label>
                                        <Form.Control type="text" value={daysPass} onChange={(e) => setDayspass(e.target.value)} placeholder="Days Pass" />
                                    </Form.Group>
                                    <Row>
                                        <Col>
                                            <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Container>
    );
}
export default TicketPass;