import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import NavBar from "./components/Navbar"
import Dashboard from "./components/Dashboard";
import Admin from "./components/Admin";
import Banner from "./components/Banner";
import Team from "./components/Team";
import Player from "./components/Player";
import NewsCategory from "./components/NewsCategory";
import News from "./components/News";
import NewsAdd from "./components/NewsAdd";
import NewsEdit from "./components/NewsEdit";
import About from "./components/About";
import Club from "./components/Club";
import Season from "./components/Season";
import Venue from "./components/Venue";
import Competition from "./components/Competition";
import Schedule from "./components/Schedule";
import Standing from "./components/Standing";
import Gallery from "./components/Gallery";
import Partner from "./components/Partner";
import NewsTag from "./components/NewsTag";
import TokenInstaFeed from "./components/TokenInstaFeed";
import TicketPass from "./components/TicketPass";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><NavBar /><Dashboard /></>}></Route>
        <Route path="/dashboard" element={<><NavBar /><Dashboard /></>}></Route>
        {/* admin */}
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/admin" element={<><NavBar /><Admin /></>}></Route>
        {/* about */}
        <Route path="/about" element={<><NavBar /><About /></>}></Route>
        {/* banner */}
        <Route path="/banner" element={<><NavBar /><Banner /></>}></Route>
        {/* club */}
        <Route path="/club" element={<><NavBar /><Club /></>}></Route>
        {/* competition */}
        <Route path="/competition" element={<><NavBar /><Competition /></>}></Route>
        {/* gallery */}
        <Route path="/gallery" element={<><NavBar /><Gallery /></>}></Route>
        {/* news */}
        <Route path="/news" element={<><NavBar /><News /></>}></Route>
        <Route path="/newsAdd" element={<><NavBar /><NewsAdd /></>}></Route>
        <Route path="/newsEdit/:id" element={<><NavBar /><NewsEdit /></>}></Route>
        {/* news category */}
        <Route path="/newsCategory" element={<><NavBar /><NewsCategory /></>}></Route>
        {/* news tag */}
        <Route path="/newsTag" element={<><NavBar /><NewsTag /></>}></Route>
        {/* partner */}
        <Route path="/partner" element={<><NavBar /><Partner /></>}></Route>
        {/* player */}
        <Route path="/player" element={<><NavBar /><Player /></>}></Route>
        {/* schedule */}
        <Route path="/schedule" element={<><NavBar /><Schedule /></>}></Route>
        {/* season */}
        <Route path="/season" element={<><NavBar /><Season /></>}></Route>
        {/* standing */}
        <Route path="/standing" element={<><NavBar /><Standing /></>}></Route>
        {/* team */}
        <Route path="/team" element={<><NavBar /><Team /></>}></Route>
        {/* venue */}
        <Route path="/venue" element={<><NavBar /><Venue /></>}></Route>
        {/* token instafeed */}
        <Route path="/tokenInstaFeed" element={<><NavBar /><TokenInstaFeed /></>}></Route>
        {/* ticket pass */}
        <Route path="/ticketPass" element={<><NavBar /><TicketPass /></>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;