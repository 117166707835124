import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { Button, Container, Form, Nav, NavDropdown, Navbar, Offcanvas, Toast, ToastContainer } from 'react-bootstrap';

import logo from '../assets/image/prawira_logo.png';

const NavBar = () => {
    const [showAdmin, setShowAdmin] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showBasketball, setShowBasketball] = useState(false);
    const [navDropdownActiveContent, setNavDropdownActiveContent] = useState(false);
    const [navDropdownActiveTeam, setNavDropdownActiveTeam] = useState(false);
    const [navDropdownActiveBasketball, setNavDropdownActiveBasketball] = useState(false);

    const [adminId, setAdminId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');

    const [showEdit, setShowEdit] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const navigate = useNavigate();

    if (localStorage.length === 0) {
        navigate('/login');
    }

    useEffect(() => {
        const currentDate = new Date();

        if (localStorage.getItem('expire') * 1000 < currentDate.getTime()) {
            refreshToken();
        }
    });

    useEffect(() => {
        const navContent = ['banner', 'gallery', 'news', 'newsCategory', 'newsTag', 'partner', 'tokenInstaFeed'];
        const navTeam = ['about', 'player', 'team'];
        const navBasketball = ['club', 'competition', 'season', 'schedule', 'standing', 'venue', 'ticketPass'];

        for (let i = 0; i < navContent.length; i++) {
            if (window.location.pathname.split('/')[1] === navContent[i]) {
                localStorage.setItem('navActive', 2 + i);
                setNavDropdownActiveContent(true);
            }
        }

        for (let i = 0; i < navTeam.length; i++) {
            if (window.location.pathname.split('/')[1] === navTeam[i]) {
                localStorage.setItem('navActive', 9 + i);
                setNavDropdownActiveTeam(true);
            }
        }

        for (let i = 0; i < navBasketball.length; i++) {
            if (window.location.pathname.split('/')[1] === navBasketball[i]) {
                localStorage.setItem('navActive', 12 + i);
                setNavDropdownActiveBasketball(true);
            }
        }

        if (window.location.pathname.split('/')[1] === 'newsAdd' || window.location.pathname.split('/')[1] === 'newsEdit') {
            localStorage.setItem('navActive', 4);
            setNavDropdownActiveContent(true);
        }
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/token', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
                },
            });
            localStorage.setItem('token', response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            localStorage.setItem('expire', decoded.exp);
        } catch (error) {
            console.log(error);
            Logout();
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();

        if (localStorage.getItem('expire') * 1000 < currentDate.getTime()) {
            const response = await axios.get(process.env.REACT_APP_SITE + '/token', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
                },
            });
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            localStorage.setItem('token', response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            localStorage.setItem('expire', decoded.exp);
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const Logout = async () => {
        try {
            await axios.delete(process.env.REACT_APP_SITE + '/logout', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
                },
            });
            navigate('/login');
            localStorage.clear();
        } catch (error) {
            console.log(error);
        }
    }

    const updateProfile = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confPassword", confPassword);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/admin/${adminId}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });

            handleClose();

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const handleForm = async (adminId) => {
        setShowEdit(true);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/admin/${adminId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setAdminId(adminId);
            setName(response.data.name);
            setEmail(response.data.email);
        } catch (error) {
            window.location.reload(true);
        }
    }

    const handleClose = () => {
        setShowEdit(false);

        setName('');
        setEmail('');
        setPassword('');
        setConfPassword('');
    }

    const handleSelect = (eventKey) => {
        localStorage.setItem('navActive', eventKey);
    };

    if (window.location.pathname === '/' || window.location.pathname.split('/')[1] === 'dashboard') {
        localStorage.setItem('navActive', 1);
    }

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark" fixed="top">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} height="30" alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" defaultActiveKey='1' activeKey={localStorage.getItem('navActive')} onSelect={handleSelect}>
                            <Nav.Link eventKey='1' href="/dashboard">Dashboard</Nav.Link>
                            <NavDropdown title="Content" id="basic-nav-dropdown" show={showContent} onClick={() => setShowContent(!showContent)} onMouseEnter={(e) => setShowContent(true)} onMouseLeave={(e) => setShowContent(false)} active={navDropdownActiveContent}>
                                <NavDropdown.Item eventKey='2' href="/banner">Banners</NavDropdown.Item>
                                <NavDropdown.Item eventKey='3' href="/gallery">Gallery</NavDropdown.Item>
                                <NavDropdown.Item eventKey='4' href="/news">News</NavDropdown.Item>
                                <NavDropdown.Item eventKey='5' href="/newsCategory">News Category</NavDropdown.Item>
                                <NavDropdown.Item eventKey='6' href="/newsTag">News Tag</NavDropdown.Item>
                                <NavDropdown.Item eventKey='7' href="/partner">Partners</NavDropdown.Item>
                                {localStorage.getItem('adminRole') === '1' ? <NavDropdown.Item eventKey='8' href="/tokenInstaFeed">Token Instagram Feed</NavDropdown.Item> : ''}
                            </NavDropdown>
                            <NavDropdown title="Team" id="basic-nav-dropdown" show={showTeam} onClick={() => setShowTeam(!showTeam)} onMouseEnter={(e) => setShowTeam(true)} onMouseLeave={(e) => setShowTeam(false)} active={navDropdownActiveTeam}>
                                <NavDropdown.Item eventKey='9' href="/about">About</NavDropdown.Item>
                                <NavDropdown.Item eventKey='10' href="/player">Players</NavDropdown.Item>
                                <NavDropdown.Item eventKey='11' href="/team">Teams</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Basketball" id="basic-nav-dropdown" show={showBasketball} onClick={() => setShowBasketball(!showBasketball)} onMouseEnter={(e) => setShowBasketball(true)} onMouseLeave={(e) => setShowBasketball(false)} active={navDropdownActiveBasketball}>
                                <NavDropdown.Item eventKey='12' href="/club">Club</NavDropdown.Item>
                                <NavDropdown.Item eventKey='13' href="/competition">Competition</NavDropdown.Item>
                                <NavDropdown.Item eventKey='14' href="/season">Season</NavDropdown.Item>
                                <NavDropdown.Item eventKey='15' href="/schedule">Schedule</NavDropdown.Item>
                                <NavDropdown.Item eventKey='16' href="/standing">Standing</NavDropdown.Item>
                                <NavDropdown.Item eventKey='17' href="/venue">Venue</NavDropdown.Item>
                                <NavDropdown.Item eventKey='18' href="/ticketPass">Ticket Pass</NavDropdown.Item>
                            </NavDropdown>
                            {localStorage.getItem('adminRole') === '1' ? <Nav.Link eventKey='19' href="/admin">Admins</Nav.Link> : ''}
                        </Nav>
                        <Nav>
                            <NavDropdown title={localStorage.getItem('adminName')} id="basic-nav-dropdown" show={showAdmin} onClick={() => setShowAdmin(!showAdmin)} onMouseEnter={(e) => setShowAdmin(true)} onMouseLeave={(e) => setShowAdmin(false)}>
                                <NavDropdown.Item onClick={() => handleForm(localStorage.getItem('adminId'))}>Profile</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={Logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Profile</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateProfile}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confitm Password</Form.Label>
                            <Form.Control type="password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default NavBar;