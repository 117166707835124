import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Container, Dropdown, DropdownButton, Form, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Admin = () => {
    const [admin, setAdmin] = useState([]);

    const [adminId, setAdminId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [role, setRole] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getAdmin(page, keyword, limit);
    }, [page, keyword, limit]);

    const getAdmin = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/admin?search_query=${keyword}&page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });

            setAdmin(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            window.location.reload(true);
        }
    }

    const saveAdmin = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confPassword", confPassword);
        formData.append("role", role);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/admin', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });

            handleClose();
            getAdmin(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateAdmin = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confPassword", confPassword);
        formData.append("role", role);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/admin/${adminId}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });

            handleClose();
            getAdmin(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClick();
                setToast(true);
            }
        }
    }

    const deleteAdmin = async (adminId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/admin/${adminId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            getAdmin(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleClick = (adminId) => {
        if (adminId) {
            setShow(true);
            setAdminId(adminId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (adminId) => {
        if (adminId) {
            setShowEdit(true);
            setShowAdd(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/admin/${adminId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setAdminId(adminId);
                setName(response.data.name);
                setEmail(response.data.email);
                setRole(response.data.role);
            } catch (error) {
                window.location.reload(true);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);

        setName('');
        setEmail('');
        setPassword('');
        setConfPassword('');
        setRole('');
    }

    const roleAdmin = (role) => {
        const roleData = ['Super Admin', 'Admin'];
        return roleData[role - 1];
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">Admin</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {admin.length === 0 ?
                        <tr>
                            <td colSpan={5} className="text-center">Empty Data</td>
                        </tr>
                        :
                        admin.map((admin, index) => (
                            <tr key={admin.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td>{admin.name}</td>
                                <td>{admin.email}</td>
                                <td>{roleAdmin(admin.role)}</td>
                                <td>
                                    {admin.id === parseInt(localStorage.getItem('adminId')) ? '' :
                                        <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                            <Dropdown.Item onClick={() => handleForm(admin.id)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleClick(admin.id)}>Delete</Dropdown.Item>
                                        </DropdownButton>
                                    }
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this admin?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteAdmin(adminId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Admin</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveAdmin}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confitm Password</Form.Label>
                            <Form.Control type="password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select value={role} onChange={(e) => setRole(e.target.value)} required>
                                <option hidden value={''}>Select Role</option>
                                <option value={1}>Super Admin</option>
                                <option value={2}>Admin</option>
                            </Form.Select>
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Admin</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateAdmin}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confitm Password</Form.Label>
                            <Form.Control type="password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} placeholder="**********" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select value={role} onChange={(e) => setRole(e.target.value)} required>
                                <option hidden value={''}>Select Role</option>
                                <option value={1}>Super Admin</option>
                                <option value={2}>Admin</option>
                            </Form.Select>
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Admin;