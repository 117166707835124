import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Container, Dropdown, DropdownButton, Form, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const NewsCategory = () => {
    const [newsCategory, setNewsCategory] = useState([]);

    const [name, setName] = useState('');
    const [newsCategoryId, setNewsCategoryId] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getNewsCategory(page, keyword, limit);
    }, [page, keyword, limit]);

    const getNewsCategory = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news_category?search_query=${keyword}&page=${page}&limit=${limit}`);
            setNewsCategory(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const saveNewsCategory = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/news_category', formData);

            handleClose();
            getNewsCategory(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateNewsCategory = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/news_category/${newsCategoryId}`, formData);

            handleClose();
            getNewsCategory(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const deleteNewsCategory = async (newsCategoryId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/news_category/${newsCategoryId}`);

            getNewsCategory(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (newsCategoryId) => {
        if (newsCategoryId) {
            setShow(true);
            setNewsCategoryId(newsCategoryId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (newsCategoryId) => {
        if (newsCategoryId) {
            setShowEdit(true);
            setShowAdd(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/news_category/${newsCategoryId}`);
                setNewsCategoryId(newsCategoryId);
                setName(response.data.name);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setName('');
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">News Category</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {newsCategory.length === 0 ?
                        <tr>
                            <td colSpan={3} className="text-center">Empty Data</td>
                        </tr>
                        :
                        newsCategory.map((newsCategory, index) => (
                            <tr key={newsCategory.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td>{newsCategory.name}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(newsCategory.id)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleClick(newsCategory.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete News Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this news category?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteNewsCategory(newsCategoryId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add News Category</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveNewsCategory}>
                        <Form.Group className="mb-3">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Category Name" required />
                        </Form.Group>
                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit News Category</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateNewsCategory}>
                        <Form.Group className="mb-3">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Category Name" required />
                        </Form.Group>
                        <Button type="submit" variant="success" className="float-end">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default NewsCategory;