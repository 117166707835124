import { faEllipsisV, faFloppyDisk, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Figure, Form, Image, Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import axios from "axios";
import { PaginationControl } from "react-bootstrap-pagination-control";

const Images = (props) => {
    const [images, setImages] = useState([]);
    const [imagesId, setImagesId] = useState([]);
    const [file, setFile] = useState([]);
    const [preview, setPreview] = useState([]);

    const [imageId, setImageId] = useState('');
    const [caption, setCaption] = useState('');

    const inputFile = useRef(null);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(12);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [showDelete, setShowDelete] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    const [selected, setSelected] = useState(null);
    const [selectedPage, setSelectedPage] = useState(0);
    const [selectedImage, setSelectedImage] = useState(false);

    const [modalPreview, setModalPreview] = useState(false);
    const [imagePreview, setImagePreview] = useState('');

    const pathUrl = window.location.pathname.replace('/', '');

    useEffect(() => {
        if (props.close) {
            handleClose();
        }

        if (selectedPage !== page) {
            setSelected(null);
        }

        if (props.show) {
            getImages(page, keyword, limit);
        }

        if (imagesId.length > 0) {
            setSelectedImage(true);
        } else {
            setSelectedImage(false);
        }
    }, [props, selectedPage, imagesId, page, keyword, limit]);

    const getImages = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/images?search_query=${keyword}&page=${page}&limit=${limit}`);
            setImages(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const loadImage = (e) => {
        const image = e.target.files;
        setFile(image);

        let imgPreview = [];

        for (let i = 0; i < image.length; i++) {
            imgPreview.push(URL.createObjectURL(image[i]));
        }

        setPreview(imgPreview);
    }

    const saveImages = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append("file", file[i]);
        }

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/images', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            });

            getImages(page, keyword, limit);
            handleClose();

            setMsg(resSave.data.msg);
            setToast(true);

            e.target[0].value = '';
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const deleteImage = async (imageId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/images/${imageId}`);

            getImages(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (imageId) => {
        if (imageId) {
            setShowDelete(true);
            setImageId(imageId);
        } else {
            setShowDelete(false);
        }
    }

    const handleClose = () => {
        setFile('');
        setPreview('');
        setSelected(null);

        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    }

    const checkboxOnChange = (e, index) => {
        if (pathUrl !== 'gallery') {
            setSelected((prev) => (index === prev ? null : index));
            setSelectedPage(page);
            setImageId(e.target.value);
            setSelectedImage(e.target.checked);
        } else {
            if (e.target.checked === true) {
                setImagesId(imgId => [...imgId, e.target.value]);
            } else {
                setImagesId(oldImgId => { return oldImgId.filter(imgId => imgId !== e.target.value) });
            }
        }
    }

    const checkboxChecked = (index, selected) => {
        if (pathUrl !== 'gallery') {
            const checked = index === selected ? 'checked' : '';
            return checked;
        }
    }

    const chooseImage = () => {
        setSelected(null);

        if (pathUrl !== 'gallery') {
            return props.image(imageId);
        } else {
            const images = imagesId;
            setImagesId([]);
            return props.image(images);
        }
    }

    const captionImage = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("caption", caption);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/images/${imageId}`, formData);

            getImages(page, keyword, limit);
            resetCaption(imageId);

            setMsg(resUpdate.data.msg);
            setToast(true);

            setCaption('');
            setImageId('');
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const disableEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            return false;
        }
    }

    const editCaption = (id) => {
        document.getElementById(id).removeAttribute('disabled');
        document.getElementById('btnAction' + id).setAttribute('style', 'display:block');

        setImageId(id);
    }

    const resetCaption = (id) => {
        document.getElementById(id).setAttribute('disabled', 'disabled');
        document.getElementById('btnAction' + id).setAttribute('style', 'display:none');
    }

    const previewImage = (path, filename) => {
        setModalPreview(true);
        setImagePreview(path + filename);
    }

    return (
        <>
            <Modal size="lg" show={props.show} onHide={props.close} scrollable={true}>
                <ToastContainer className="p-3 position-absolute top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                    <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">{msg}</strong>
                            <small></small>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>

                <Modal.Header closeButton>
                    <Modal.Title>Choose Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={saveImages}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column lg={3}>Upload Images</Form.Label>
                            <Col xs={10} lg={6}>
                                <Form.Control type="file" onChange={loadImage} ref={inputFile} multiple required />
                            </Col>
                            <Col xs={2} lg={2}>
                                <Button type="submit" variant="success" className="float-end"><FontAwesomeIcon icon={faUpload} /> {window.innerWidth > 991 ? 'Upload' : ''}</Button>
                            </Col>
                        </Form.Group>
                        <Row>
                            {preview.length ? preview.map((preview, index) => (
                                <Col xs={6} md={4} lg={3} key={index}>
                                    <Figure>
                                        <Figure.Image alt="image" src={preview} />
                                        <Figure.Caption style={{ wordBreak: 'break-all', fontSize: '12px' }}>{file[index].name}</Figure.Caption>
                                    </Figure>
                                </Col>
                            ))
                                : ''
                            }
                        </Row>
                    </Form>

                    <hr />

                    <Row className="mt-3">
                        <Col lg={12}>
                            <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                            <Col lg={2} className="float-start">
                                <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                                    <option value="12">12</option>
                                    <option value="28">28</option>
                                    <option value="60">60</option>
                                    <option value="120">120</option>
                                </Form.Select>
                            </Col>
                            <Col lg={4} className="float-end mt-3">
                                <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                            </Col>
                        </Col>
                    </Row>

                    {images.length === 0 ?
                        <Row className="text-center">
                            <span>Empty Data</span>
                        </Row>
                        :
                        <Row xs={2} md={3} lg={4} className="g-4 pb-5 mt-1 gallery">
                            {images.map((images, index) => (
                                <Col key={index}>
                                    <Card style={{ background: 'none' }} className="h-100">
                                        <Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + images.path + images.name} />
                                        <Card.Body>
                                            <Card.Title style={{ wordBreak: 'break-all', fontSize: '12px' }}>{images.name}</Card.Title>
                                            <Form.Check type="checkbox" checked={checkboxChecked(index, selected)} onChange={(e) => checkboxOnChange(e, index)} value={images.id} style={{ position: 'absolute', top: '0', left: '5px', fontSize: '24px' }} />
                                            <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm" style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                <Dropdown.Item onClick={() => previewImage(images.path, images.name)}>Preview</Dropdown.Item>
                                                <Dropdown.Item onClick={() => editCaption(images.id)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleClick(images.id)}>Delete</Dropdown.Item>
                                            </DropdownButton>
                                        </Card.Body>
                                        <Form onSubmit={captionImage}>
                                            <Container id={'btnAction' + images.id} style={{ display: 'none' }}>
                                                <Button type="submit" variant="success" size="sm" className="float-end ms-1" title="Save" style={{ fontSize: '12px', position: 'absolute', right: '5px', marginTop: '-30px' }}><FontAwesomeIcon icon={faFloppyDisk} size="lg" /></Button>
                                                <Button type="reset" variant="danger" size="sm" className="float-end" title="Cancel" style={{ fontSize: '12px', position: 'absolute', right: '40px', marginTop: '-30px' }} onClick={() => resetCaption(images.id)}><FontAwesomeIcon icon={faXmark} size="lg" /></Button>
                                            </Container>
                                            <Form.Control as="textarea" size="sm" rows={3} id={images.id} onChange={(e) => setCaption(e.target.value)} defaultValue={images.caption || ''} onKeyDown={(e) => disableEnter(e)} style={{ overflowY: 'scroll', resize: 'none' }} disabled="disabled" />
                                        </Form>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    }

                    <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
                    <div className="float-end">
                        <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.close}>Close</Button>
                    <Button variant="primary" onClick={chooseImage} disabled={!selectedImage}>Choose</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this image?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteImage(imageId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalPreview} onHide={() => setModalPreview(false)} centered>
                <Modal.Header closeButton></Modal.Header>
                <Image src={process.env.REACT_APP_SITE_IMAGE + imagePreview} width='100%' />
            </Modal>
        </>
    );
}

export default Images;