import React, { useState, useEffect } from "react";
import axios from "axios";
import { Badge, Button, Col, Container, Dropdown, DropdownButton, Figure, Form, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const News = () => {
    const [news, setNews] = useState([]);
    const [newsDetail, setNewsDetail] = useState([]);

    const [newsId, setNewsId] = useState('');
    const [newsTag, setNewsTag] = useState([]);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getNews(page, keyword, limit);
    }, [page, keyword, limit]);

    const getNews = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news_join?search_query=${keyword}&page=${page}&limit=${limit}`);
            setNews(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteNews = async (newsId) => {
        try {
            const resDelete = await axios.delete(process.env.REACT_APP_SITE + `/news/${newsId}`);

            getNews(page, keyword, limit);
            handleClick();

            setMsg(resDelete.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (newsId) => {
        if (newsId) {
            setShow(true);
            setNewsId(newsId);
        } else {
            setShow(false);
        }
    }

    const updateNewsPublish = async (newsId, publish) => {
        const formData = new FormData();
        formData.append("publish", publish);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/news_publish/${newsId}`, formData);

            getNews(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                setToast(true);
            }
        }
    }

    const handleDetail = async (newsId) => {
        setShowDetail(true);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news_join/${newsId}`);
            setNewsDetail(response.data);
            setNewsTag([]);

            let tagId = response.data.tag_id;

            if (tagId) {
                try {
                    const response = await axios.get(process.env.REACT_APP_SITE + `/news_tag_all?tags=${tagId}`);
                    setNewsTag(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }

    }

    const handleClose = () => {
        setShowDetail(false);
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">News</h1>
            <Button href={`/newsAdd`} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Subject</th>
                        <th>Category</th>
                        <th>Creator</th>
                        <th>Editor</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {news.length === 0 ?
                        <tr>
                            <td colSpan={8} className="text-center">Empty Data</td>
                        </tr>
                        :
                        news.map((news, index) => (
                            <tr key={news.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td><Link className="link" onClick={() => handleDetail(news.id)}>{news.subject}</Link></td>
                                <td>{news.category}</td>
                                <td>{news.creator}</td>
                                <td>{news.editor}</td>
                                <td>{news.publish === 1 ? 'Publish' : 'Unpublish'}</td>
                                <td>{news.publish ? new Date(news.date_publish).toISOString().slice(0, 10) + ' ' + new Date(news.date_publish).toTimeString().slice(0, 5) : ''}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item href={`newsEdit/${news.id}`}>Edit</Dropdown.Item>
                                        {news.publish === 1 ?
                                            <Dropdown.Item onClick={() => updateNewsPublish(news.id, 0)}>Unpublish</Dropdown.Item> :
                                            <Dropdown.Item onClick={() => updateNewsPublish(news.id, 1)}>Publish</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={() => handleClick(news.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete News</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this news?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteNews(newsId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail News</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Form.Group className="mb-3">
                            <Figure>
                                <Figure.Image style={{ width: "100%" }} alt="image" src={process.env.REACT_APP_SITE_IMAGE + newsDetail.path + newsDetail.filename} />
                                <Form.Label style={{ fontSize: '12px' }}>{newsDetail.caption}</Form.Label>
                            </Figure>
                            <Form.Label className="fw-bold">Copyright by :</Form.Label><br />
                            <Form.Label>{newsDetail.image_copyright}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Subject :</Form.Label><br />
                            <Form.Label>{newsDetail.subject}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Category :</Form.Label><br />
                            <Form.Label>{newsDetail.category}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Description :</Form.Label>
                            <p dangerouslySetInnerHTML={{ __html: newsDetail.description }}></p>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Creator :</Form.Label><br />
                            <Form.Label>{newsDetail.creator}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Editor :</Form.Label><br />
                            <Form.Label>{newsDetail.editor}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Status :</Form.Label><br />
                            <Form.Label>{newsDetail.publish === 1 ? 'Publish' : 'Unpublish'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date :</Form.Label><br />
                            <Form.Label>{newsDetail.publish === 1 ? new Date(newsDetail.date_publish).toISOString().slice(0, 10) + ' ' + new Date(newsDetail.date_publish).toTimeString().slice(0, 5) : ''}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Tags :</Form.Label><br />
                            <Form.Label>
                                {newsTag ?
                                    newsTag.map((tag, index) => (
                                        <Badge key={index} bg="primary" className="me-1">{tag.name}</Badge>
                                    ))
                                    : ''}
                            </Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default News;