import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Badge, Button, Col, Container, Dropdown, DropdownButton, Figure, Form, Modal, Offcanvas, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const Schedule = () => {
    const [schedule, setSchedule] = useState([]);
    const [schedule_detail, setScheduleDetail] = useState([]);
    const [competition, setCompetition] = useState([]);
    const [season, setSeason] = useState([]);
    const [team, setTeam] = useState([]);
    const [venue, setVenue] = useState([]);

    const [scheduleId, setScheduleId] = useState('');
    const [name, setName] = useState('');
    const [competition_id, setCompetitionId] = useState('');
    const [season_id, setSeasonId] = useState('');
    const [team1, setTeam1] = useState('');
    const [team1_score, setTeam1Score] = useState(0);
    const [team2, setTeam2] = useState('');
    const [team2_score, setTeam2Score] = useState(0);
    const [match_date, setMatchDate] = useState('');
    const [venue_id, setVenueId] = useState('');
    const [status, setStatus] = useState('');
    const [ticket, setTicket] = useState('');
    const [ticket_passport, setTicketPassport] = useState('');
    const [ticket_biru, setTicketBiru] = useState('');
    const [stream, setStream] = useState('');
    const [stats, setStats] = useState('');
    const [highlight, setHighlight] = useState('');
    const [buy_ticket_on_apps, setBuyTicketOnApps] = useState(false);
    const [event_id_on_apps, setEventIdOnApps] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [msg, setMsg] = useState('');
    const [toast, setToast] = useState(false);

    useEffect(() => {
        getSchedule(page, keyword, limit);
        getCompetition();
        getSeason();
        getVenue();
    }, [page, keyword, limit]);

    const getSchedule = async (page, keyword, limit) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/schedule_join?search_query=${keyword}&page=${page}&limit=${limit}`);
            setSchedule(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const getCompetition = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/competition_all');
            setCompetition(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getSeason = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/season_all');
            setSeason(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getTeam = async (season_id) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/club_all?season_id=${season_id}`);
            setTeam(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getVenue = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/venue_all');
            setVenue(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const saveSchedule = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("competition_id", competition_id);
        formData.append("season_id", season_id);
        formData.append("team1", team1);
        formData.append("team1_score", team1_score);
        formData.append("team2", team2);
        formData.append("team2_score", team2_score);
        formData.append("match_date", match_date);
        formData.append("venue_id", venue_id);
        formData.append("status", status);
        formData.append("ticket", ticket);
        formData.append("ticket_passport", ticket_passport);
        formData.append("ticket_biru", ticket_biru);
        formData.append("stream", stream);
        formData.append("stats", stats);
        formData.append("highlight", highlight);
        formData.append("buy_ticket_on_apps", buy_ticket_on_apps);
        formData.append("event_id_on_apps", event_id_on_apps);

        try {
            const resSave = await axios.post(process.env.REACT_APP_SITE + '/schedule', formData);

            handleClose();
            getSchedule(page, keyword, limit);

            setMsg(resSave.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const updateSchedule = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        formData.append("competition_id", competition_id);
        formData.append("season_id", season_id);
        formData.append("team1", team1);
        formData.append("team1_score", team1_score);
        formData.append("team2", team2);
        formData.append("team2_score", team2_score);
        formData.append("match_date", match_date);
        formData.append("venue_id", venue_id);
        formData.append("status", status);
        formData.append("ticket", ticket);
        formData.append("ticket_passport", ticket_passport);
        formData.append("ticket_biru", ticket_biru);
        formData.append("stream", stream);
        formData.append("stats", stats);
        formData.append("highlight", highlight);
        formData.append("buy_ticket_on_apps", buy_ticket_on_apps);
        formData.append("event_id_on_apps", event_id_on_apps);

        try {
            const resUpdate = await axios.patch(process.env.REACT_APP_SITE + `/schedule/${scheduleId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            handleClose();
            getSchedule(page, keyword, limit);

            setMsg(resUpdate.data.msg);
            setToast(true);
        } catch (error) {
            console.log(error);
            setMsg(error.response.data.msg);

            if (error.response.status) {
                handleClose();
                setToast(true);
            }
        }
    }

    const deleteSchedule = async (scheduleId) => {
        try {
            await axios.delete(process.env.REACT_APP_SITE + `/schedule/${scheduleId}`);

            getSchedule(page, keyword, limit);
            handleClick();
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (scheduleId) => {
        if (scheduleId) {
            setShow(true);
            setScheduleId(scheduleId);
        } else {
            setShow(false);
        }
    }

    const handleForm = async (scheduleId) => {
        if (scheduleId) {
            setShowEdit(true);
            setShowAdd(false);
            setShowDetail(false);

            try {
                const response = await axios.get(process.env.REACT_APP_SITE + `/schedule/${scheduleId}`);
                setScheduleId(scheduleId);
                setName(response.data.name);
                setCompetitionId(response.data.competition_id);
                setSeasonId(response.data.season_id);
                setTeam1(response.data.team1);
                setTeam1Score(response.data.team1_score);
                setTeam2(response.data.team2);
                setTeam2Score(response.data.team2_score);
                setMatchDate(response.data.match_date);
                setVenueId(response.data.venue_id);
                setStatus(response.data.status);
                setTicket(response.data.ticket || '');
                setTicketPassport(response.data.ticket_passport || '');
                setTicketBiru(response.data.ticket_biru || '');
                setStream(response.data.stream || '');
                setStats(response.data.stats || '');
                setHighlight(response.data.highlight || '');
                setBuyTicketOnApps(response.data.buy_ticket_on_apps);
                setEventIdOnApps(response.data.event_id_on_apps || '');

                getTeam(response.data.season_id);
            } catch (error) {
                console.log(error);
            }
        } else {
            setShowAdd(true);
            setShowEdit(false);
            setShowDetail(false);
        }
    }

    const handleDetail = async (scheduleId) => {
        setShowDetail(true);
        setShowAdd(false);
        setShowEdit(false);

        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/schedule_join/${scheduleId}`);
            setScheduleDetail(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);

        setScheduleId('');
        setName('');
        setCompetitionId('');
        setSeasonId('');
        setTeam1('');
        setTeam1Score(0);
        setTeam2('');
        setTeam2Score(0);
        setMatchDate('');
        setVenueId('');
        setStatus('');
        setTicket('');
        setTicketPassport('');
        setTicketBiru('');
        setStream('');
        setStats('');
        setHighlight('');
        setBuyTicketOnApps(false);
        setEventIdOnApps('');
    }

    const gameStatus = (status) => {
        const gameStatus = ['', 'RESULT', 'NEXT', 'UPCOMING', 'POSTPONED'];
        let statusGame = gameStatus[status];

        return statusGame;
    }

    const getClubSeason = (season_id) => {
        setSeasonId(season_id);
        getTeam(season_id);
    }

    return (
        <Container className="pb-5 pt-5">
            <ToastContainer className="p-3 position-fixed top-0 end-0 mt-5" style={{ zIndex: 1 }}>
                <Toast show={toast} onClose={() => setToast(false)} delay={5000} autohide>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{msg}</strong>
                        <small></small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>

            <h1 className="title mt-5">Schedule</h1>
            <Button onClick={() => handleForm()} variant="success">Add</Button>
            <Row className="mt-3">
                <Col lg={12}>
                    <Form.Label className="float-start mt-4 me-2">Show</Form.Label>
                    <Col lg={1} className="float-start">
                        <Form.Select className="mt-3" value={limit} onChange={(e) => setLimit(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </Col>
                    <Col lg={2} className="float-end mt-3">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                    </Col>
                </Col>
            </Row>
            <Table striped bordered hover className="mt-3" responsive="sm">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Competition</th>
                        <th>Season</th>
                        <th>Team 1</th>
                        <th>Team 2</th>
                        <th>Match Date</th>
                        <th>Game Status</th>
                        <th>Opt</th>
                    </tr>
                </thead>
                <tbody>
                    {schedule.length === 0 ?
                        <tr>
                            <td colSpan={9} className="text-center">Empty Data</td>
                        </tr>
                        :
                        schedule.map((schedule, index) => (
                            <tr key={schedule.id}>
                                <td>{(limit * (page - 1)) + (index + 1)}</td>
                                <td><Link className="link" onClick={() => handleDetail(schedule.id)}>{schedule.name}</Link></td>
                                <td>{schedule.competition}</td>
                                <td>{schedule.season}</td>
                                <td>{schedule.team1}</td>
                                <td>{schedule.team2}</td>
                                <td>{schedule.match_date ? new Date(schedule.match_date).toISOString().slice(0, 10) + ' ' + new Date(schedule.match_date).toTimeString().slice(0, 5) : ''}</td>
                                <td>{gameStatus(schedule.status)}</td>
                                <td>
                                    <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />} variant="secondary" size="sm">
                                        <Dropdown.Item onClick={() => handleForm(schedule.id)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleClick(schedule.id)}>Delete</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Form.Label className="mt-2">Total Rows: {rows} Page: {page} of {pages}</Form.Label>
            <div className="float-end">
                <PaginationControl page={page} between={4} total={rows} limit={limit} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </div>

            <Modal show={show} onHide={handleClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete this schedule?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClick()}>Close</Button>
                    <Button variant="danger" onClick={() => deleteSchedule(scheduleId)}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas show={showAdd} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Schedule</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={saveSchedule} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Schedule Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Schedule name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Competition</Form.Label>
                            <Form.Select value={competition_id} onChange={(e) => setCompetitionId(e.target.value)} required>
                                <option hidden value={''}>Select Competition</option>
                                {competition.map((competition, index) => (
                                    <option value={competition.id} key={competition.id}>{competition.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Season</Form.Label>
                            <Form.Select value={season_id} onChange={(e) => getClubSeason(e.target.value)} required>
                                <option hidden value={''}>Select Season</option>
                                {season.map((season, index) => (
                                    <option value={season.id} key={season.id}>{season.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 1</Form.Label>
                            <Form.Select value={team1} onChange={(e) => setTeam1(e.target.value)} required>
                                <option hidden value={''}>Select Team 1</option>
                                {team.map((team, index) => (
                                    team.name === 'Prawira Harum Bandung' || team.name === 'Prawira Bandung' ?
                                        <option value={team.id} key={team.id} className="fw-bold text-primary">{team.name}</option>
                                        :
                                        <option value={team.id} key={team.id}>{team.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 1 Score</Form.Label>
                            <Form.Control type="number" value={team1_score} onChange={(e) => setTeam1Score(e.target.value)} placeholder="Team 1 Score" min={0} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 2</Form.Label>
                            <Form.Select value={team2} onChange={(e) => setTeam2(e.target.value)} required>
                                <option hidden value={''}>Select Team 2</option>
                                {team.map((team, index) => (
                                    team.name === 'Prawira Harum Bandung' || team.name === 'Prawira Bandung' ?
                                        <option value={team.id} key={team.id} className="fw-bold text-primary">{team.name}</option>
                                        :
                                        <option value={team.id} key={team.id}>{team.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 2 Score</Form.Label>
                            <Form.Control type="number" value={team2_score} onChange={(e) => setTeam2Score(e.target.value)} placeholder="Team 2 Score" min={0} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Match Date</Form.Label>
                            <Col>
                                <DatePicker className="form-control" timeInputLabel="Time:" dateFormat="yyyy-MM-dd hh:mm aa" showTimeInput selected={match_date} onChange={(e) => setMatchDate(e)} placeholderText="yyyy-MM-dd hh:mm aa" fixedHeight required />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Venue</Form.Label>
                            <Form.Select value={venue_id} onChange={(e) => setVenueId(e.target.value)} required>
                                <option hidden value={''}>Select Venue</option>
                                {venue.map((venue, index) => (
                                    <option value={venue.id} key={venue.id}>{venue.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Status Game</Form.Label>
                            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} required>
                                <option hidden value={''}>Select Status</option>
                                <option value={1}>RESULT</option>
                                <option value={2}>NEXT</option>
                                <option value={3}>UPCOMING</option>
                                <option value={4}>POSTPONED</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Umum Link</Form.Label>
                            <Form.Control type="text" value={ticket} onChange={(e) => setTicket(e.target.value)} placeholder="Ticket Umum Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Passport Link</Form.Label>
                            <Form.Control type="text" value={ticket_passport} onChange={(e) => setTicketPassport(e.target.value)} placeholder="Ticket Passport Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Biru Link</Form.Label>
                            <Form.Control type="text" value={ticket_biru} onChange={(e) => setTicketBiru(e.target.value)} placeholder="Ticket Biru Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stream Link</Form.Label>
                            <Form.Control type="text" value={stream} onChange={(e) => setStream(e.target.value)} placeholder="Stream Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stats Link</Form.Label>
                            <Form.Control type="text" value={stats} onChange={(e) => setStats(e.target.value)} placeholder="Stats Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Highlight Link</Form.Label>
                            <Form.Control type="text" value={highlight} onChange={(e) => setHighlight(e.target.value)} placeholder="Highlight Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Buy Ticket On Apps</Form.Label>
                            <Form.Check type="switch" label={buy_ticket_on_apps ? "On" : "Off"} checked={buy_ticket_on_apps} onChange={() => setBuyTicketOnApps(!buy_ticket_on_apps)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Event ID On Apps</Form.Label>
                            <Form.Control type="text" value={event_id_on_apps} onChange={(e) => setEventIdOnApps(e.target.value)} placeholder="Event ID On Apps" />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showEdit} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Schedule</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={updateSchedule} className="pb-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Schedule Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Schedule name" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Competition</Form.Label>
                            <Form.Select value={competition_id} onChange={(e) => setCompetitionId(e.target.value)} required>
                                <option hidden value={''}>Select Competition</option>
                                {competition.map((competition, index) => (
                                    <option value={competition.id} key={competition.id}>{competition.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Season</Form.Label>
                            <Form.Select value={season_id} onChange={(e) => getClubSeason(e.target.value)} required>
                                <option hidden value={''}>Select Season</option>
                                {season.map((season, index) => (
                                    <option value={season.id} key={season.id}>{season.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 1</Form.Label>
                            <Form.Select value={team1} onChange={(e) => setTeam1(e.target.value)} required>
                                <option hidden value={''}>Select Team 1</option>
                                {team.map((team, index) => (
                                    team.name === 'Prawira Harum Bandung' || team.name === 'Prawira Bandung' ?
                                        <option value={team.id} key={team.id} className="fw-bold text-primary">{team.name}</option>
                                        :
                                        <option value={team.id} key={team.id}>{team.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 1 Score</Form.Label>
                            <Form.Control type="number" value={team1_score} onChange={(e) => setTeam1Score(e.target.value)} placeholder="Team 1 Score" min={0} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 2</Form.Label>
                            <Form.Select value={team2} onChange={(e) => setTeam2(e.target.value)} required>
                                <option hidden value={''}>Select Team 2</option>
                                {team.map((team, index) => (
                                    team.name === 'Prawira Harum Bandung' || team.name === 'Prawira Bandung' ?
                                        <option value={team.id} key={team.id} className="fw-bold text-primary">{team.name}</option>
                                        :
                                        <option value={team.id} key={team.id}>{team.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Team 2 Score</Form.Label>
                            <Form.Control type="number" value={team2_score} onChange={(e) => setTeam2Score(e.target.value)} placeholder="Team 2 Score" min={0} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Match Date</Form.Label>
                            <Col>
                                <DatePicker className="form-control" timeInputLabel="Time:" dateFormat="yyyy-MM-dd hh:mm aa" showTimeInput selected={match_date ? new Date(match_date) : null} onChange={(e) => setMatchDate(e)} placeholderText="yyyy-mm-dd hh:mm aa" fixedHeight required />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Venue</Form.Label>
                            <Form.Select value={venue_id} onChange={(e) => setVenueId(e.target.value)} required>
                                <option hidden value={''}>Select Venue</option>
                                {venue.map((venue, index) => (
                                    <option value={venue.id} key={venue.id}>{venue.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Status Game</Form.Label>
                            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} required>
                                <option hidden value={''}>Select Status</option>
                                <option value={1}>RESULT</option>
                                <option value={2}>NEXT</option>
                                <option value={3}>UPCOMING</option>
                                <option value={4}>POSTPONED</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Umum Link</Form.Label>
                            <Form.Control type="text" value={ticket} onChange={(e) => setTicket(e.target.value)} placeholder="Ticket Umum Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Passport Link</Form.Label>
                            <Form.Control type="text" value={ticket_passport} onChange={(e) => setTicketPassport(e.target.value)} placeholder="Ticket Passport Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticket Biru Link</Form.Label>
                            <Form.Control type="text" value={ticket_biru} onChange={(e) => setTicketBiru(e.target.value)} placeholder="Ticket Biru Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stream Link</Form.Label>
                            <Form.Control type="text" value={stream} onChange={(e) => setStream(e.target.value)} placeholder="Stream Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stats Link</Form.Label>
                            <Form.Control type="text" value={stats} onChange={(e) => setStats(e.target.value)} placeholder="Stats Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Highlight Link</Form.Label>
                            <Form.Control type="text" value={highlight} onChange={(e) => setHighlight(e.target.value)} placeholder="Highlight Link" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Buy Ticket On Apps</Form.Label>
                            <Form.Check type="switch" label={buy_ticket_on_apps ? "On" : "Off"} checked={buy_ticket_on_apps} onChange={() => setBuyTicketOnApps(!buy_ticket_on_apps)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Event ID On Apps</Form.Label>
                            <Form.Control type="text" value={event_id_on_apps} onChange={(e) => setEventIdOnApps(e.target.value)} placeholder="Event ID On Apps" />
                        </Form.Group>

                        <Button type="submit" variant="success" className="float-end ms-1">Save</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showDetail} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Detail Schedule</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="pb-5">
                        <Table>
                            <tbody>
                                <tr>
                                    <td className="text-center">
                                        <Figure.Image width={90} alt="image" src={process.env.REACT_APP_SITE_IMAGE + schedule_detail.url_team1} /><br />
                                        <h2>{schedule_detail.team1_score}</h2>
                                    </td>
                                    <td className="text-center align-middle fw-bold">
                                        <h3>VS</h3>
                                        {gameStatus(schedule_detail.status)}
                                    </td>
                                    <td className="text-center">
                                        <Figure.Image width={90} alt="image" src={process.env.REACT_APP_SITE_IMAGE + schedule_detail.url_team2} /><br />
                                        <h2>{schedule_detail.team2_score}</h2>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Schedule Name :</Form.Label><br />
                            <Form.Label>{schedule_detail.name}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Competition :</Form.Label><br />
                            <Form.Label>{schedule_detail.competition}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Season :</Form.Label><br />
                            <Form.Label>{schedule_detail.season}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Team 1 :</Form.Label><br />
                            <Form.Label>{schedule_detail.team1}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Team 2 :</Form.Label><br />
                            <Form.Label>{schedule_detail.team2}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Match Date :</Form.Label><br />
                            <Form.Label>{schedule_detail.match_date ? new Date(schedule_detail.match_date).toISOString().slice(0, 10) + ' ' + new Date(schedule_detail.match_date).toTimeString().slice(0, 5) : ''}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Venue :</Form.Label><br />
                            <Form.Label>{schedule_detail.venue}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Ticket Umum Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.ticket || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Ticket Passport Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.ticket_passport || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Ticket Biru Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.ticket_biru || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Stream Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.stream || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Stats Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.stats || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Highlight Link :</Form.Label><br />
                            <Form.Label>{schedule_detail.highlight || '-'}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Buy Ticket On Apps :</Form.Label><br />
                            <Form.Label>{schedule_detail.buy_ticket_on_apps === 1 ? <Badge bg="primary" className="me-1">ON</Badge> : <Badge bg="danger" className="me-1">OFF</Badge>}</Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Event ID On Apps :</Form.Label><br />
                            <Form.Label>{schedule_detail.event_id_on_apps || '-'}</Form.Label>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default Schedule;