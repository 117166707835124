import { Container } from "react-bootstrap";

const Dashboard = () => {
    return (
        <Container className="pb-5 pt-5 mt-5">
            <h1 className="title">Halaman Dashboard</h1>
            <h1 className="title">Selamat Datang {localStorage.getItem('adminName')}</h1>
        </Container>
    );
}

export default Dashboard;